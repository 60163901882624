import { Injectable, signal } from '@angular/core';
import { Observable, ReplaySubject, catchError, filter, map, tap } from 'rxjs';

import { GetFeatureFlagGQL } from './hobbyts.service';
import { errorHandler } from '../utils/handlers.utils';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private feature = signal(false);

  constructor(private getFeatureFlagGql: GetFeatureFlagGQL) {}

  public getFeatureFlag(): Observable<void> {
    const result = new ReplaySubject<void>(1);
    this.getFeatureFlagGql
      .fetch()
      .pipe(
        map(({ data }) => data?.getFeatureFlag?.isActive),

        tap((feature: any) => {
          if (feature === undefined || feature === null) {
            this.feature.set(true);
          } else {
            this.feature.set(feature);
          }
        }),
        catchError((error) => {
          this.feature.set(true);
          return errorHandler(error);
        })
      )
      .subscribe(() => {
        result.next();
        result.complete();
      });

    return result;
  }

  public getFeature(): boolean {
    return this.feature();
  }
}
